<template>
  <div>
    <div>
      <h4 class="workplatform-title m-t-10 m-b-10">计划作业品牌</h4>
      <Table
        size="small"
        stripe
        :data="tableData.list"
        :columns="tableColumns"
      ></Table>
      <div class="paging_style">
        <Page
          size="small"
          :total="tableData.totalRow"
          :page-size="query.pageSize"
          show-total
          show-elevator
          :current="query.pageNumber"
          @on-change="changePage"
        ></Page>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 15,
        startDate: '',
        endDate: '',
        stationId: null
      },
      // total: 0,
      // workList: [],
      tableColumns: [
        { title: '品牌名', align: 'center', key: 'brandName' },
        {
          title: '发布周期',
          align: 'center',
          width: 140,
          render: (h, params) => {
            return h('span', `${params.row.startDate} 至 ${params.row.endDate} (${params.row.publishDays}天)`)
          }
        },
        {
          title: '作业数', align: 'center', key: 'taskitemCount'
        }
      ]
    }
  },
  computed: {
    tableData () {
      return this.$store.state.board.brandTableData
    }
  },
  methods: {
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.$store.dispatch('getBrandSummaryData', this.query)
    }
  }
}
</script>
